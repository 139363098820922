import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0afa86ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "viewer-container",
  ref: "viewerContainerRef"
}
const _hoisted_2 = {
  class: "part-model-viewer-modal-viewer grayscale",
  ref: "modelViewerRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.isShowUnavailableScreen)
      ? (_openBlock(), _createBlock($setup["PreviewUnavailableScreen"], { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode($setup["Loader"], {
      loading: $setup.loading && $props.showLoader
    }, null, 8, ["loading"]),
    _createElementVNode("div", _hoisted_2, null, 512)
  ], 512))
}