import { toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bef7b73a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-to-input" }
const _hoisted_2 = ["tabindex"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(["label", {'label-blue': $props.blueLabel && !$props.currentValue}]),
      ref: "spanRef",
      onClick: $setup.onSpanClicked,
      onFocus: $setup.onFocus,
      tabindex: $props.isTabIndexable ? 1 : undefined
    }, _toDisplayString($props.currentValue || $props.label || "Enter value"), 43, _hoisted_2), [
      [_vShow, !$setup.showInput]
    ]),
    _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent($props.isNumberInput ? $setup.NInputNumber : $setup.NInput), {
      value: $setup.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.value) = $event)),
      placeholder: $props.autofocus ? $props.label : '',
      showButton: false,
      min: $props.isNumberInput ? 0 : undefined,
      ref: "inputRef",
      class: _normalizeClass({'uppercase-input-value': $props.isUppercaseValue}),
      onKeydownPassive: [
        _withKeys($setup.onClose, ["esc"]),
        _withKeys($setup.onAdd, ["enter"]),
        _withKeys($setup.onTabClicked, ["tab"])
      ],
      onBlur: $setup.onSendValue,
      maxlength: $props.maxlength,
      max: $props.numberMaxLength || undefined
    }, null, 40, ["value", "placeholder", "min", "class", "onKeydownPassive", "maxlength", "max"])), [
      [_vShow, $setup.showInput]
    ])
  ])), [
    [$setup["vClickOutside"], $setup.onClose]
  ])
}